<template>
  <b-overlay
      :show="overlay"
      rounded="sm"
  >
    <div>
      <b-row>
        <b-col cols="12">

          <b-card>

            <validation-observer
                ref="refFormObserver"
            >

              <b-form>
                <b-row>

                  <!-- Field:  title -->
                  <b-col
                      cols="12"
                      md="6"
                  >
                    <b-form-group
                        label="عنوان"
                        label-for="title"
                    >
                      <validation-provider
                          #default="{ errors }"
                          rules="required"
                      >
                        <b-form-input
                            id="title"
                            v-model="defaultData.title"
                            :state="errors.length > 0 ? false:null"
                        />
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Field:  owner -->
                  <b-col
                      cols="12"
                      md="6"
                  >
                    <b-form-group
                        label="برگزار کننده"
                        label-for="owner"
                    >
                      <validation-provider
                          #default="{ errors }"
                          rules="required"
                      >
                        <b-form-input
                            id="owner"
                            v-model="defaultData.owner"
                            :state="errors.length > 0 ? false:null"
                        />
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Field:  startTime -->
                  <b-col
                      cols="12"
                      md="6"
                  >
                    <b-form-group
                        label="تاریخ و زمان شروع"
                        label-for=""
                    >
                      <validation-provider
                          #default="{ errors }"
                          rules="required"
                      >
                        <input
                            type="text"
                            :id="`start${1}`"
                            placeholder="شروع"
                            :value="createJalaliDate(defaultData.startDateTime)"
                            :state="errors.length > 0 ? false:null"
                            class="form-control form-control-lg w-11/12 2xl:h-12 h-10 mx-auto mt-1 mb-1 text-sm block w-full rounded-full text-center bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 placeholder-inputPlaceHolder text-gray-700"
                        />
                      </validation-provider>
                      <custom-date-picker
                          v-model="defaultData.startDateTime"
                          auto-submit
                          input-format="YYYY-MM-DD HH:mm"
                          format="YYYY-MM-DD HH:mm"
                          display-format="jYYYY/jMM/jDD HH:mm"
                          class=""
                          :element="`start${1}`"
                          type="datetime"
                          color="#5c6bc0"
                          :min="new Date()"
                      ></custom-date-picker>
                    </b-form-group>
                  </b-col>

                  <!-- Field:  endTime -->
                  <b-col
                      cols="12"
                      md="6"
                  >
                    <b-form-group
                        label="تاریخ و زمان پایان"
                        label-for=""
                    >
                      <validation-provider
                          #default="{ errors }"
                          rules="required"
                      >
                        <input
                            type="text"
                            :id="`end${2}`"
                            placeholder="پایان"
                            :value="createJalaliDate(defaultData.endDateTime)"
                            :state="errors.length > 0 ? false:null"
                            class="form-control form-control-lg w-11/12 2xl:h-12 h-10 mx-auto mt-1 mb-1 text-sm block w-full rounded-full text-center bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 placeholder-inputPlaceHolder text-gray-700"
                        />
                      </validation-provider>
                      <custom-date-picker
                          v-model="defaultData.endDateTime"
                          auto-submit
                          input-format="YYYY-MM-DD HH:mm"
                          format="YYYY-MM-DD HH:mm"
                          display-format="jYYYY/jMM/jDD HH:mm"
                          class=""
                          :element="`end${2}`"
                          type="datetime"
                          color="#5c6bc0"
                          :min="new Date()"
                      ></custom-date-picker>
                    </b-form-group>
                  </b-col>


                  <!-- Field:  price -->
                  <b-col
                      cols="12"
                      md="6"
                  >
                    <b-form-group
                        label="قیمت هر صندلی (تومان)"
                        label-for="price"
                    >
                      <validation-provider
                          #default="{ errors }"
                          rules="required"
                      >
                        <b-form-input
                            id="price"
                            v-model="defaultData.price"
                            :state="errors.length > 0 ? false:null"
                        />
                      </validation-provider>
                    </b-form-group>
                  </b-col>

                  <!-- Field: productCategoryId -->
                  <b-col
                      v-if="saloons !== null"
                      cols="12"
                      md="6"
                  >
                    <b-form-group
                        label="انتخاب سالن"
                        label-for="saloonId"
                    >
                      <v-select
                          v-model="defaultData.saloonId"
                          :options="saloons"
                          label="name"
                          :reduce="name => name.saloonId"
                          :clearable="false"
                          input-id="saloonId"
                      />
                    </b-form-group>
                  </b-col>

                  <!-- Field:  showTime -->
                  <b-col
                      cols="12"
                  >
                    <b-form-group
                        label="تاریخ و زمان ثبت نام"
                        label-for=""
                    >
                      <validation-provider
                          #default="{ errors }"
                          rules="required"
                      >
                        <input
                            type="text"
                            :id="`startRegister${1}`"
                            placeholder="شروع"
                            :value="createJalaliDate(defaultData.showTime)"
                            :state="errors.length > 0 ? false:null"
                            class="form-control form-control-lg w-11/12 2xl:h-12 h-10 mx-auto mt-1 mb-1 text-sm block w-full rounded-full text-center bg-gray-100 border-transparent focus:border-gray-500 focus:bg-white focus:ring-0 placeholder-inputPlaceHolder text-gray-700"
                        />
                      </validation-provider>
                      <custom-date-picker
                          v-model="defaultData.showTime"
                          auto-submit
                          input-format="YYYY-MM-DD HH:mm"
                          format="YYYY-MM-DD HH:mm"
                          display-format="jYYYY/jMM/jDD HH:mm"
                          class=""
                          :element="`startRegister${1}`"
                          type="datetime"
                          color="#5c6bc0"
                          :min="new Date()"
                      ></custom-date-picker>
                    </b-form-group>
                  </b-col>

                  <!-- Field:  description -->
                  <b-col
                      cols="12"
                  >
                    <span class="my-2">توضیحات</span>
                    <quill-editor
                        v-model="defaultData.description"
                    />
                  </b-col>

                </b-row>
              </b-form>

            </validation-observer>

            <!-- Action Buttons -->
            <b-button
                variant="primary"
                class="mt-1 mb-sm-0 mr-0 mr-sm-1 col-12 col-md-2"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="validationForm"
            >
              ثبت
            </b-button>

          </b-card>

          <b-card v-if="rowIds.length !== 0">

            <h4 class="mb-2">ردیف های انتخاب شده برای رزرو</h4>

            <b-badge
                v-for="item in rowIds"
                variant="danger"
                class="p-2 cursor-pointer m-1"
                @click="removeRow(item)"
            >
              <span> ردیف شماره {{ item.rowNumber }} </span>
              <feather-icon
                  icon="XIcon"
                  class="mx-25"
                  size="20"
              />
            </b-badge>

          </b-card>

          <b-card v-if="defaultData.saloonId !== null">

            <h4 class="mb-2">انتخاب ردیف های مورد نظر برای رزرو توسط ادمین</h4>

            <b-row>

              <b-col
                  v-if="sections !== null"
                  cols="12"
                  md="6"
              >
                <b-form-group
                    label="انتخاب جایگاه"
                    label-for="section"
                >
                  <v-select
                      v-model="section"
                      :options="sections"
                      label="name"
                      :reduce="name => name.sectionId"
                      :clearable="false"
                      input-id="section"
                  />
                </b-form-group>
              </b-col>

              <b-col
                  v-if="rows !== null"
                  cols="12"
                  md="6"
              >
                <b-form-group
                    label="انتخاب ردیف"
                    label-for="section"
                >
                  <v-select
                      v-model="row"
                      :options="rows"
                      label="rowNumber"
                      :reduce="name => name"
                      :clearable="false"
                      input-id="section"
                  />
                </b-form-group>
              </b-col>

            </b-row>
            <b-row class="ml-1">
              <b-button
                  variant="primary"
                  class="col-12 col-md-2"
                  :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                  @click="addToSelectedRows"
              >
                ثبت ردیف
              </b-button>
            </b-row>
          </b-card>

        </b-col>
      </b-row>
    </div>
  </b-overlay>
</template>

<script>
import {
  BAlert, BAvatar,
  BButton,
  BCard,
  BCardBody,
  BCardText,
  BCol,
  BDropdown,
  BDropdownItem, BForm, BFormGroup, BFormInput, BFormTextarea,
  BImg,
  BLink, BMedia,
  BRow, BTab, BTabs, BOverlay, BBadge
} from "bootstrap-vue";
import vSelect from "vue-select";
import ToastificationContent from "@core/components/toastification/ToastificationContent";
import {SeminarCreateSeminarRequest} from "@/libs/Api/Seminar";
import {ValidationObserver, ValidationProvider} from "vee-validate";
import {required} from '@validations'
import {SaloonGetAllRequest} from "@/libs/Api/Saloon";
import {SectionGetBySaloonIdRequest} from "@/libs/Api/Section";
import {RowGetAllBySectionIdRequest} from "@/libs/Api/Row";
import Helper from "@/libs/Helper";


export default {
  name: "SeminarAdd",
  title: "اضافه کردن سمینار - پنل ادمین مکس ",
  computed: {
    saloonId() {
      return this.defaultData.saloonId;
    },
    currentDate() {
      let today = new Date();
      let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
      let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
      return date + ' ' + time;
    },
    today() {
      let options = {year: 'numeric', month: '2-digit', day: '2-digit'};
      let test = new Date().toLocaleDateString('fa-IR', options);
      return test.replace(/([۰-۹])/g, token => String.fromCharCode(token.charCodeAt(0) - 1728));
    },
  },
  data() {
    return {
      required,
      overlay: false,
      saloons: null,
      sections: null,
      rows: null,
      section: null,
      row: null,
      rowIds: [],
      defaultData: {
        isDeleted: false,
        seminarId: 0,
        title: "",
        startDateTime: null,
        endDateTime: null,
        owner: "",
        hasEnded: false,
        createDate: this.currentDate,
        price: null,
        saloonId: null,
        preReserved: '',
        showTime:null,
        description:'',
      },
    }
  },
  async created() {
    await this.getSaloons();
  },
  methods: {
    validationForm() {
      let _this = this;

      _this.$refs.refFormObserver.validate().then(success => {
        if (success) {
          // eslint-disable-next-line
          _this.createSeminar();
        }
      })
    },
    async getSaloons() {
      let _this = this;

      let data = {
        pageNumber: 0,
        count: 0
      }

      let saloonGetAllRequest = new SaloonGetAllRequest(_this);
      saloonGetAllRequest.setParams(data);
      await saloonGetAllRequest.fetch(function (content) {
        _this.saloons = content.saloons;
      }, function (error) {
        console.log(error);
      })
    },
    async createSeminar() {
      let _this = this;
      _this.overlay = true;

      if (_this.rowIds.length !== 0) {
        let tempArr = [];
        _this.rowIds.forEach(el => {
          tempArr.push(el.rowId);
        })
        _this.defaultData.preReserved = Helper.myJoin(tempArr);
      }

      let seminarCreateSeminarRequest = new SeminarCreateSeminarRequest(_this);
      seminarCreateSeminarRequest.setParams(_this.defaultData);
      await seminarCreateSeminarRequest.fetch(function (content) {
        _this.overlay = false;
        _this.$toast({
          component: ToastificationContent,
          position: 'bottom-center',
          props: {
            title: `عملیات موفق`,
            icon: 'CheckIcon',
            variant: 'success',
            text: `عملیات انجام شد.`,
          },
        })
        _this.$router.push({name: 'apps-seminar-list'})
      }, function (error) {
        console.log(error);
        _this.overlay = false;
      })
    },
    async getSections() {
      let _this = this;
      _this.overlay = true;

      let sectionGetBySaloonIdRequest = new SectionGetBySaloonIdRequest(_this);
      sectionGetBySaloonIdRequest.setId(_this.defaultData.saloonId);
      await sectionGetBySaloonIdRequest.fetch(function (content) {
        _this.overlay = false;
        content.forEach(el => {
          if (el.level === 1) {
            el.name = `${'همکف ' + el.name}`
          } else {
            el.name = `${'بالکن ' + el.name}`
          }
        })
        _this.sections = content;
      }, function (error) {
        _this.overlay = false;
        console.log(error)
      })
    },
    async getRows(param) {
      let _this = this;
      _this.overlay = true;

      let rowGetAllBySectionIdRequest = new RowGetAllBySectionIdRequest(_this);
      rowGetAllBySectionIdRequest.setId(param);
      await rowGetAllBySectionIdRequest.fetch(function (content) {
        _this.overlay = false;
        _this.rows = content;
      }, function (error) {
        _this.overlay = false;
        console.log(error)
      })
    },
    addToSelectedRows() {
      let _this = this;

      _this.rowIds.push(_this.row);
      _this.rows = null;
      _this.row = null;
    },
    removeRow(param) {
      let _this = this;

      _this.rowIds = Helper.arrayRemove(_this.rowIds, param);
    },
    createJalaliDate(param){
      if (param !== null){
        console.log(param)
        return param.slice(11, 16) + ' - ' + new Date(param.slice(0, 10)).toLocaleDateString("fa-IR",);
      }
    },
  },
  watch: {
    saloonId: function (nv, ov) {
      this.getSections();
    },
    section: function (nv, ov) {
      this.getRows(nv);
    },
  },
  components: {
    BCard,
    BCardBody,
    BRow,
    BCol,
    BImg,
    BCardText,
    BLink,
    BButton,
    BDropdown,
    BDropdownItem,
    BAlert,
    BTab,
    BTabs,
    BForm,
    BFormGroup,
    BFormInput,
    BFormTextarea,
    BMedia,
    BAvatar,
    vSelect,
    BOverlay,
    BBadge,
    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
}
</script>

<style scoped>

</style>